import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './searchpage.module.css';
import useTitle from '../../hooks/useTitle';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchGetArticle,
	fetchSearchArticle,
	selectLoadingArticle,
	selectResultArticleSearch,
	selectServerErrorArticle,
	setLoadingArticle,
	setResultArticle,
	setSelectedArticle,
	setServerErrorArticle
} from '../../store/articleSlice';
import { useHistory, Link } from 'react-router-dom';
import emptySearch from './../../assets/images/empty-search.svg';
import { Header, Loader, Search, ServerError } from 'src/components';
import {trackPage} from "../../utils/track";

const transition = {
	duration: 1,
	ease: [0.43, 0.13, 0.23, 0.96]
};

const imageVariants = {
	exit: { height: 0, overflow: 'hidden', transition },
	exitToArticle: {},
	enter: {
		height: '80vh',
		overflow: 'hidden',
		background: 'white',
		transition
	}
};

const ResultList = ({ articles, getArticle }) => (
	<ul className={styles.results}>
		{articles.map(article => {
			return (
				<li key={article._id} onClick={() => getArticle(article)}>
					<p>{article.name}</p>
				</li>
			);
		})}
	</ul>
);

const NotFind = () => (
	<div className={styles.message}>
		<p>
			<b>Tu búsqueda no tuvo resultados</b>
		</p>
		<img src={emptySearch} alt="albo no hay resultados search" />
		<p>
			<b>
				Intenta con otras palabras o explora las <Link to="/">categorías</Link>.
			</b>
		</p>
	</div>
);

const SearchPage = () => {
	useTitle('Busqueda - albo');
	const dispatch = useDispatch();
	const [exitToArticle, setExitToArticle] = useState(false);
	const history = useHistory();
	const articles = useSelector(selectResultArticleSearch);
	const serverError = useSelector(selectServerErrorArticle);
	const loadingArticle = useSelector(selectLoadingArticle);
	const [searchStart, setSearchStart] = useState(false);

	const debounceQuery = useCallback(
		debounce(async query => {
			if (query.length >= 3) {
				await dispatch(fetchSearchArticle(query));
			}
		}, 1000),
		[]
	);

	const handleSearch = query => {
		dispatch(setServerErrorArticle(false));
		if (query.length >= 3) {
			dispatch(setLoadingArticle(true));
			setSearchStart(true);
		} else {
			dispatch(setResultArticle([]));
			dispatch(setLoadingArticle(false));
			setSearchStart(false);
		}
		debounceQuery(query);
	};

	useEffect(() => {
		trackPage({ page: '/search' });
	}, []);

	const getArticle = async article => {
		await dispatch(fetchGetArticle(article._id));
		dispatch(setSelectedArticle(article));
		setExitToArticle(() => true);
		history.push(`/article/${article._id}`);
	};

	return (
		<div className={styles.container}>
			<Header />
			<Search focus={true} showCancel={true} search={handleSearch} />
			<motion.div initial="exit" animate="enter" exit={exitToArticle ? '' : 'exit'} variants={imageVariants}>
				{!loadingArticle && articles?.length > 0 && !serverError && (
					<ResultList getArticle={getArticle} articles={articles} />
				)}
				{loadingArticle && searchStart && <Loader height={73} />}
				{!loadingArticle && articles?.length === 0 && !serverError && searchStart && <NotFind />}
				{serverError && <ServerError />}
			</motion.div>
		</div>
	);
};

export default SearchPage;
